import restApiClient from "./client";
import { ApiResponse, PaginatedApiResponse } from "../types/api.types";
import {
  Charging,
  ChargingAssignDriverPayload,
  UpdateChargingPayerToOrgPayload
} from "../types/charging-sessions.types";
import { generateApiEndpoint } from "utils/api.utils";

const serviceName = "charging";

/**
 * GET: '/admin/charging'
 */
export async function getChargingSessionsAsync(query?: string) {
  const endpoint = generateApiEndpoint(serviceName);
  return (await restApiClient.get<PaginatedApiResponse<Charging>>(endpoint + query)).data;
}


export async function getChargingSessionAsync(id: string) {
  const endpoint = generateApiEndpoint(`${serviceName}/${id}`);
  return (await restApiClient.get<ApiResponse<Charging>>(endpoint)).data;
}


/**
 * PATCH: '/admin/charging/id'
 */
export async function assignDriverToChargingAsync(id: string, payload: ChargingAssignDriverPayload) {
  const endpoint = generateApiEndpoint(`${serviceName}/${id}`);
  await restApiClient.patch(endpoint, payload);
}

/**
 * PATCH: '/admin/charging/id'
 */
export async function updateChargingPayerToOrgAsync(id: string, payload: UpdateChargingPayerToOrgPayload) {
  const endpoint = generateApiEndpoint(`${serviceName}/${id}`);
  await restApiClient.patch(endpoint, payload);
}