import { User } from "./user.types";
import { Driver } from "./driver.types";
import { PaymentState } from "./payment.types";
import { Control, DeepMap, FieldError, RegisterOptions } from "react-hook-form";
import { useDriverFormsReimbursementStepStates } from "../hooks/driver.hooks";

export interface Reimbursement {
  createdAt: string;
  updatedAt: string;
  version: number;
  id: string;
  organizationId: string;
  userId: string;
  period: string;
  periodStartedAt: string;
  periodEndedAt: string;
  paymentState: PaymentState;
  sessionCount: number;
  energyDelivered: string;
  duration: number;
  payoutRate: number;
  taxRate: number;
  grossAmount: number;
  taxAmount: number;
  netAmount: number;
  organization: User;
  user: Driver;
}


export type UpdateReimbursementPaylaod = {
  "paymentState": PaymentState
}

export type LatLng = {
  lat: number;
  lng: number;
};

export type ReimbursementRectangleBound = { north: number; south: number; east: number; west: number }

export type ReimbursementMapReadonlyProps = {
  zoom: number,
  address: string,
  center: LatLng | undefined,
  bounds: ReimbursementRectangleBound | undefined,
  onMapEditButtonPressed?: () => void,
  isGoogleApiLoaded: boolean,
  isLoading: undefined | boolean,
  isReimbursementActive: boolean,
}


export type DriverFormReimbursementStepStateHooks = ReturnType<typeof useDriverFormsReimbursementStepStates>;


export type ReimbursementFormState = {
  fixedRate: string
}


export type ReimbursementFormStepsCommonProps = {
  register: (options: RegisterOptions) => React.LegacyRef<HTMLInputElement> | undefined;
  control: Control<any>;
  errors: DeepMap<any, FieldError>;
  isEditMode?: boolean;
  stepTitleOverride?: JSX.Element;
};


export interface MonthlyTrend {
  lastMonth: number;
  thisMonth: number;
  percentageChange: any;
}


export interface TotalEnergyOverview {
  total: number;
  monthlyTrend: MonthlyTrend;
}

export interface TotalCostOverview {
  total: number;
  monthlyTrend: MonthlyTrend;
}


export interface ReimbursementOverview {
  totalEnergyOverview: TotalEnergyOverview;
  totalCostOverview: TotalCostOverview;
}


export type ReimbursementStatistic = {
  period: string
  totalEnergy: number
  totalCost: number
}


export enum ReimbursementPaymentState {
  UNPAID = "UNPAID",
  PAID = "PAID",
  VOID = "VOID"
}


export type ReimbursementFilters = {
  paymentState?: {
    $in: ReimbursementPaymentState[]
  },
  q?: {
    $eq?: string
  }
}
