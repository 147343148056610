import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { getChargingSessionRowForCsv, getVehicleChargingSessionRowForCsv } from "utils/charging.utils";
import { useChargingSessionCommonTableColumns } from "../../../hooks/charging-session-hooks";
import { useVehicleChargingListAsync } from "../../../hooks/data/vehicle-data-accessor.hooks";
import { VehicleChargingDetail } from "../../../types/vehicle.types";
import Row from "../../custom/Row";

import PaginatedTableCsvLinkButton from "components/button/PaginatedTableCsvLinkButton";
import ChargingFilterMenu from "components/charging-sessions-components/filter/ChargingFilterMenu";
import PaginatedTable from "components/table/PaginatedTable";
import { useStripeInvoiceUrlAccessor } from "hooks/invoice.hooks";
import { useUrlPagination } from "hooks/pagination.hooks";
import { getVehicleChargingListByIdAsync } from "services/vehicle.services";
import FilterSearchInput from "../../filter/FilterSearchInput";

type Props = {};

export type VehicleChargingSessionsTableColumn = {
  name: string;
  selector?: (row?: VehicleChargingDetail) => JSX.Element | string | any;
  width?: string;
  cell?: (row?: any) => JSX.Element | string;
};

function VehicleChargingSessionsTable({}: Props) {
  const { tablePageRowSize } = useUrlPagination();
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const { isLoading, vehicleChargingList, chargingListMeta } = useVehicleChargingListAsync();

  const { t } = useTranslation("common");

  const history = useHistory();

  const commonTableColumns = useChargingSessionCommonTableColumns();

  const { handleForwardToStripeAsync, isForwarding } = useStripeInvoiceUrlAccessor();

  const columns = useMemo(
    () => [
      commonTableColumns.driver,
      commonTableColumns.state,
      commonTableColumns.source,
      commonTableColumns.chargerName,
      commonTableColumns.energy,
      commonTableColumns.cost,
      commonTableColumns.payStat,
      commonTableColumns.stripeInvoiceId(handleForwardToStripeAsync, isForwarding),
      commonTableColumns.whoPays,
      commonTableColumns.date,
      commonTableColumns.action
    ],
    [commonTableColumns, handleForwardToStripeAsync, isForwarding]
  ) as VehicleChargingSessionsTableColumn[];

  const areThereAnyChargingDetails = vehicleChargingList?.length > 0;

  return (
    <div className="mt-4">

      {areThereAnyChargingDetails && (
        <Row className="justify-between my-2 align-center">
          <FilterSearchInput placeholder="Search name, vehicle or charger (min 2 char.)" />

          <PaginatedTableCsvLinkButton
            fileName="vehicle-charging-sessions"
            csvRowFormatter={getVehicleChargingSessionRowForCsv}
            totalPages={chargingListMeta?.pagination.totalPages ?? 0}
            rowSizePerPage={tablePageRowSize}
            fetchListAsync={(query: string) => getVehicleChargingListByIdAsync(vehicleId, query)}
          />
        </Row>
      )}

      <ChargingFilterMenu />

      <PaginatedTable
        isLoading={isLoading}
        getRoutePath={id => `/charging-sessions/${id}`}
        paginationTotalRows={chargingListMeta?.pagination.totalElements}
        columns={columns}
        data={vehicleChargingList}
      />
    </div>
  );
}

export default VehicleChargingSessionsTable;
