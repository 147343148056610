import React, { useCallback, useState } from "react";
import HorizontalLine from "../../line/HorizontalLine";
import Row from "../../custom/Row";
import { BlackBody2Text200 } from "../../text/Text";
import FrunkOpenIcon from "../../icon/icons/FrunkOpenIcon";
import TrunkOpenIcon from "../../icon/icons/TrunkOpenIcon";
import FlashLightsIcon from "../../icon/icons/FlashLightsIcon";
import HonkHornIcon from "../../icon/icons/HonkHornIcon";
import { useAlertManager } from "../../../hooks/alert.hooks";
import { updateVehicleTeslaAsync } from "../../../services/vehicle.services";
import { TeslaCommand, TeslaDataAccessorHook } from "../../../types/vehicle.types";
import { handleApiErrorResponse } from "../../../utils";
import { useVehicleIdFromParams } from "../../../hooks/vehicle.hooks";
import { getVehicleCommandAlertSuccessMessage } from "../../../utils/vehicle.utils";
import VehicleDetailsVirtualKeyControlledButton from "./VehicleDetailsVirtualKeyControlledButton";

type Props = {
  teslaHook: TeslaDataAccessorHook;
  isControlsVisibleByKeyStatus?: boolean
}

function VehicleDetailsTeslaControlEditors({ teslaHook, isControlsVisibleByKeyStatus }: Props) {
  const { vehicleId } = useVehicleIdFromParams();
  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitCommandAsync = useCallback(async (command: TeslaCommand) => {
    try {
      setIsSubmitting(true);

      await updateVehicleTeslaAsync(vehicleId, {
        command: command
      });

      handleOpenSuccessAlert(getVehicleCommandAlertSuccessMessage(command));
    } catch (error) {
      handleOpenErrorAlert(handleApiErrorResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  }, [handleOpenErrorAlert, handleOpenSuccessAlert, vehicleId]);


  const canActuateTrunks = teslaHook.teslaInfo?.trunks.canActuate;

  return (
    <>
      {canActuateTrunks && <>
        <Row className="justify-between align-items-center">
          <Row className="align-items-center">
            <BlackBody2Text200 className={"mr-4"}>Frunk</BlackBody2Text200>
            <VehicleDetailsVirtualKeyControlledButton
              isVirtualKeyNeeded={!isControlsVisibleByKeyStatus}
              onClick={() => handleSubmitCommandAsync(TeslaCommand.ACTUATE_FRONT_TRUNK)}
              isLoading={isSubmitting}
              leftIcon={<FrunkOpenIcon />} />
          </Row>
          <Row className="align-items-center">
            <BlackBody2Text200 className={"mr-4"}>Trunk</BlackBody2Text200>
            <VehicleDetailsVirtualKeyControlledButton
              isVirtualKeyNeeded={!isControlsVisibleByKeyStatus}
              onClick={() => handleSubmitCommandAsync(TeslaCommand.ACTUATE_REAR_TRUNK)}
              isLoading={isSubmitting}
              leftIcon={<TrunkOpenIcon />} />
          </Row>
        </Row>

        <HorizontalLine />
      </>}


      <Row className="justify-between align-items-center">
        <Row className="align-items-center">
          <BlackBody2Text200 className={"mr-4"}>Flash Lights</BlackBody2Text200>
          <VehicleDetailsVirtualKeyControlledButton
            isVirtualKeyNeeded={!isControlsVisibleByKeyStatus}
            onClick={() => handleSubmitCommandAsync(TeslaCommand.FLASH_LIGHTS)}
            isLoading={isSubmitting}
            leftIcon={<FlashLightsIcon />}
          />
        </Row>
        <Row className="align-items-center">
          <BlackBody2Text200 className={"mr-4"}>Honk Horn</BlackBody2Text200>
          <VehicleDetailsVirtualKeyControlledButton
            isVirtualKeyNeeded={!isControlsVisibleByKeyStatus}
            onClick={() => handleSubmitCommandAsync(TeslaCommand.HONK_HORN)}
            isLoading={isSubmitting}
            leftIcon={<HonkHornIcon />}
          />
        </Row>
      </Row>
    </>
  );
}

export default VehicleDetailsTeslaControlEditors;