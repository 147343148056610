import SmartCarWheelLogo from "assets/images/vehicle/SmartcarWheelLogo.png";
import { TeslaDataAccessorHook, VehicleDataAccessorHook, VehicleDetailsEditFormMode } from "types/vehicle.types";
import { getLocalFormattedDateForTables } from "utils/date.utils";
import { getVehicleConnectionStatus } from "utils/vehicle.utils";
import Col from "../../custom/Col";

import OutlinedButtonWithIcon from "components/button/OutlinedButtonWithIcon";
import PrimaryButton from "components/button/PrimaryButton";
import TeslaIcon from "components/icon/icons/TeslaIcon";
import { useSmartCar, useTeslaConnection } from "hooks/vehicle.hooks";
import Row from "../../custom/Row";
import LoadingSkeleton from "../../loading/LoadingSkeleton";
import { BlackBodyText100, BlackH5Text100, BlackOverlineText200 } from "../../text/Text";
import VehicleDetailsInfoButtonAndModal from "./VehicleDetailsInfoButtonAndModal";
import VehicleAvatar from "../../user/VehicleAvatar";
import VehicleDetailsEditFormModal from "./VehicleDetailsEditFormModal";
import React, { useState } from "react";
import VehicleDetailsHeaderInfoBlock from "./VehicleDetailsHeaderInfoBlock";
import ConnectionStatusBadge from "../../badge/ConnectionStatusBadge";
import VehicleDriverScheduleDriverList from "../../vehicle-driver-schedule/VehicleDriverScheduleDriverList";
import VehicleDetailsVehicleStateBlock from "./VehicleDetailsVehicleStateBlock";
import { VehicleDriverSchedulesDataAccessorHook } from "../../../types/vehicle-driver-schedule.types";
import ClipboardWithCopyIcon from "../../clipboard/ClipboardWithCopyIcon";
import PencilIcon from "../../icon/icons/PencilIcon";

type Props = {
  readonly vehicleHook: VehicleDataAccessorHook;
  readonly teslaHook: TeslaDataAccessorHook
  readonly vehicleDriverSchedulesHook: VehicleDriverSchedulesDataAccessorHook

};

function VehicleDetailsHeader({ vehicleHook, teslaHook, vehicleDriverSchedulesHook }: Props) {
  const { handleOpenTeslaConnectionFlow } = useTeslaConnection();
  const { handleSmartCarOpenDialog } = useSmartCar();
  const { vehicle } = vehicleHook;

  const [isEditVehicleFormModalOpen, setIsEditVehicleFormModalOpen] = useState(false);

  const { isManualAddedCar, connectionStatus } = getVehicleConnectionStatus(vehicleHook.vehicle);

  const addedAt = getLocalFormattedDateForTables(vehicle?.createdAt);

  const isLoading = vehicleHook.isLoading;


  const isTeslaBrand = vehicle?.carBrand === "TESLA";


  return (
    <>
      <VehicleDetailsEditFormModal
        vehicle={vehicle}
        defaultValue={vehicle?.name}
        formMode={VehicleDetailsEditFormMode.NAME}
        isModalOpen={isEditVehicleFormModalOpen}
        setIsModalOpen={setIsEditVehicleFormModalOpen}
        triggerVehicleFetching={vehicleHook.refetch}
      />


      <Row className={"w-100"}>

        <Col md={2} className=" ">
          <VehicleAvatar imgSource={vehicle?.imagesBaseUrl} />
        </Col>

        <Col md={10} className="py-2 px-3">
          {isLoading ? (
            <LoadingSkeleton className={"w-400px w-max-100 my-2"} height={30} count={1} />
          ) : (
            <Row className="justify-between align-items-center mt-2 p-1">
              <Row className="d-flex align-items-center ">
                <BlackH5Text100 className="mb-2 mb-md-0">{vehicle?.name}</BlackH5Text100>

                <OutlinedButtonWithIcon isSmall={true} className="ml-3 clickable" leftIcon={<PencilIcon />}
                                        onClick={() => setIsEditVehicleFormModalOpen(true)} />


                <VehicleDetailsInfoButtonAndModal />
              </Row>

              {isManualAddedCar && (
                <Row className="align-items-center">
                  <BlackOverlineText200 className="mr-2">Connect with</BlackOverlineText200>
                  {isTeslaBrand ? <PrimaryButton
                    className="ml-2"
                    onClick={handleOpenTeslaConnectionFlow}
                    leftIcon={<TeslaIcon fill="white" className="mr-2" />}
                    text="Tesla API"
                  /> : <OutlinedButtonWithIcon
                    leftIcon={<img width={20} src={SmartCarWheelLogo} alt="smartcar icon" />}
                    text="Smartcar"
                    onClick={handleSmartCarOpenDialog}
                  />
                  }
                </Row>
              )}
            </Row>
          )}


          <Row className="my-1 p-1 align-items-center">
            {!isManualAddedCar && (
              <VehicleDetailsVehicleStateBlock vehicleHook={vehicleHook} teslaHook={teslaHook} />
            )
            }
          </Row>


          <Row className="justify-between w-100">
            <Col lg="2" className="p-1">
              <VehicleDetailsHeaderInfoBlock isLoading={isLoading} label={"Status"} textOverwrite={
                <div className={"w-100"}>
                  <ConnectionStatusBadge
                    connectionStatus={connectionStatus} />
                </div>
              }
              />
            </Col>
            <Col lg="3" className="p-1">
              <VehicleDetailsHeaderInfoBlock isLoading={isLoading}
                                             label={"VIN"}
                                             textOverwrite={<ClipboardWithCopyIcon
                                               text={vehicleHook.vehicle?.vin}
                                               TextComponent={BlackBodyText100} />}
              />
            </Col>
            <Col lg="3" className="p-1">
              <VehicleDetailsHeaderInfoBlock isLoading={isLoading}
                                             label={"Assignments"}
                                             textOverwrite={
                                               <VehicleDriverScheduleDriverList
                                                 vehicleDriverSchedulesHook={vehicleDriverSchedulesHook}
                                               />
                                             }
              />
            </Col>

            <Col lg="3" className="p-1">
              <VehicleDetailsHeaderInfoBlock isLoading={isLoading} label={"Added"} text={addedAt} />
            </Col>
          </Row>

        </Col>

      </Row>
    </>
  );
}

export default VehicleDetailsHeader;


/*


* */