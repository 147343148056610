import React from "react";
import RowBetweenBlock from "../../block/RowBetweenBlock";
import { BlackBodyText100, BlackLinkText100 } from "../../text/Text";
import LoadingSkeleton from "../../loading/LoadingSkeleton";
import { getDriverFullName, getDriverPaymentResponsibilityLabelByPayerType } from "../../../utils/driver.utils";
import { useHistory } from "react-router";
import { getFormattedPhoneNumber } from "../../../utils/format.utils";
import ArrowUpRightIcon from "../../icon/icons/ArrowUpRightIcon";
import { VehicleDriverSchedulesDataAccessorHook } from "../../../types/vehicle-driver-schedule.types";
import CurrentDriverAssignmentIcon from "../../icon/icons/CurrentDriverAssignmentIcon";
import { getCategorizedSchedules } from "../../../utils/vehicle-driver-schedule.utils";
import HoverUnderlinedTextButton from "components/button/HoverUnderlinedTextButton";
import Row from "../../custom/Row";

type Props = {
  vehicleDriverSchedulesHook: VehicleDriverSchedulesDataAccessorHook
}

function VehicleDetailsDriverAssignment({ vehicleDriverSchedulesHook }: Props) {
  const { isLoading: isSchedulesLoading, schedules } = vehicleDriverSchedulesHook;


  const history = useHistory();

  const { activeSchedules } = getCategorizedSchedules(schedules);
  const isDriverAssigned = activeSchedules && activeSchedules.length > 0;


  if (isSchedulesLoading) {
    return <LoadingSkeleton count={4} />;
  }

  if (!isDriverAssigned) {
    return (
      <div className={"h-250px center"}>
        <BlackBodyText100 className="my-4">No assignment yet.</BlackBodyText100>
      </div>
    );
  }


  const firstSchedule = activeSchedules[0];
  const driver = firstSchedule.user;
  const driverEmail = driver.email;
  const phoneNumber = getFormattedPhoneNumber(driver?.phoneNumber);
  return (
    <div>
      <CurrentDriverAssignmentIcon />
      <RowBetweenBlock
        className={"mt-2"}
        leftText={"Driver"}
        rightOverwrite={<BlackLinkText100
          onClick={() => history.push(`/drivers/${driver.id}`)}>{getDriverFullName(driver)}</BlackLinkText100>}
      />
      <RowBetweenBlock
        leftText={"Phone number"}
        rightText={phoneNumber}
      />
      <RowBetweenBlock
        leftText={"Email"}
        rightOverwrite={
        <HoverUnderlinedTextButton
          textOverride={<Row className="align-items-center fs-16px">
            <ArrowUpRightIcon />
            {driverEmail}
          </Row>}
          onClick={() => window.open("mailto:" + driverEmail)}
          className="d-flex align-items-center clickable"
        >

        </HoverUnderlinedTextButton>}
      />

      <RowBetweenBlock
        leftText={"Payment resp."}
        rightText={getDriverPaymentResponsibilityLabelByPayerType(driver?.payer)}
      />

    </div>
  );


}

export default VehicleDetailsDriverAssignment;