import { ChargingListTableColumn } from "components/charging-sessions-components/ChargingSessionsDataTable";
import Row from "components/custom/Row";
import { BlackBodyText300, BlackH5Text100 } from "components/text/Text";
import { useChargingSessionCommonTableColumns } from "hooks/charging-session-hooks";
import { useDriverChargingListAsync } from "hooks/data/driver-data-accessor.hooks";
import { useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { getChargingSessionRowWithDriverForCsv } from "utils/charging.utils";

import PaginatedTableCsvLinkButton from "components/button/PaginatedTableCsvLinkButton";
import ChargingFilterMenu from "components/charging-sessions-components/filter/ChargingFilterMenu";
import PaginatedTable from "components/table/PaginatedTable";
import { useStripeInvoiceUrlAccessor } from "hooks/invoice.hooks";
import { useUrlPagination } from "hooks/pagination.hooks";
import { getDriverChargingListByIdAsync } from "services/driver.services";
import { Driver } from "types/driver.types";

type Props = {
  driver?: Driver;
};

function DriverDetailsChargingTable({ driver }: Props) {
  const history = useHistory();

  const { driverId } = useParams<{ driverId: string }>();

  const { tablePageRowSize } = useUrlPagination();
  const { chargingListMeta, isLoading, driverChargingList } = useDriverChargingListAsync();

  const commonTableColumns = useChargingSessionCommonTableColumns();

  const { handleForwardToStripeAsync, isForwarding } = useStripeInvoiceUrlAccessor();

  const columns = useMemo(
    () => [
      commonTableColumns.vehicle,
      commonTableColumns.state,
      commonTableColumns.source,
      commonTableColumns.chargerName,
      commonTableColumns.energy,
      commonTableColumns.cost,
      commonTableColumns.payStat,
      commonTableColumns.stripeInvoiceId(handleForwardToStripeAsync, isForwarding),
      commonTableColumns.whoPays,
      commonTableColumns.date,
      commonTableColumns.action
    ],
    [commonTableColumns, handleForwardToStripeAsync, isForwarding]
  ) as ChargingListTableColumn[];

  const areThereAnyChargingDetails = driverChargingList?.length > 0;

  return (
    <>
      <Row className="justify-between my-2 align-center  pt-2">
        <Row className="center">
          <BlackH5Text100>Charging Sessions</BlackH5Text100>
          <BlackBodyText300 className={"ml-2"}>
            {chargingListMeta?.pagination?.totalElements ?? 0} sessions
          </BlackBodyText300>
        </Row>
        {areThereAnyChargingDetails && driver && (
          <div className="mt-2 mt-md-0">
            <PaginatedTableCsvLinkButton
              fileName="driver-charging-sessions"
              csvRowFormatter={(c) => getChargingSessionRowWithDriverForCsv(c, driver)}
              totalPages={chargingListMeta?.pagination.totalPages ?? 0}
              rowSizePerPage={tablePageRowSize}
              fetchListAsync={(query: string) => getDriverChargingListByIdAsync(driverId, query)}
            />
          </div>
        )}
      </Row>

      <ChargingFilterMenu />
      <PaginatedTable
        paginationTotalRows={chargingListMeta?.pagination.totalElements}
        columns={columns}
        data={driverChargingList}
        isLoading={isLoading}
        getRoutePath={id => `/charging-sessions/${id}`}
      />
    </>
  );
}

export default DriverDetailsChargingTable;
