import { useAppDispatch } from "hooks/redux.hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getWeeklySessionStatisticsAsync } from "services/dashboard.service";
import { getChargingSessionAsync, getChargingSessionsAsync } from "../../services/charging.service";
import { PaginationMeta } from "../../types/api.types";
import { Charging, ChargingQuery, TotalEnergyAndCostByPeriodAndProvider, } from "../../types/charging-sessions.types";
import { useLocation, useParams } from "react-router";



export const useChargingListAsync = () => {
    const [chargingList, setChargingList] = useState<Array<Charging>>([])
    const [chargingListMeta, setChargingListMeta] = useState<PaginationMeta | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(true);

    const { search } = useLocation();


    const getChargingListAsyncCallback = useCallback(
        async (queryString: string) => {
            try {
                setIsLoading(true);
                const res = await getChargingSessionsAsync(queryString)
                setChargingList(res.data)
                setChargingListMeta(res.meta)

            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        },
        [],
    )


    useEffect(() => {
        getChargingListAsyncCallback(search);
    }, [getChargingListAsyncCallback, search]);


    return { chargingList, chargingListMeta, isLoading, getChargingListAsyncCallback }
}



export const useChargingAsync = () => {
    const { chargingSessionId } = useParams<{ chargingSessionId?: string }>();

    const [charging, setCharging] = useState<Charging | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(true);




    const getChargingSessionAsyncCallback = useCallback(
        async () => {
            try {
                setIsLoading(true);

                setCharging((await getChargingSessionAsync(chargingSessionId ?? '')).data)
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        },
        [chargingSessionId],
    )


    useEffect(() => {
        getChargingSessionAsyncCallback();
    }, [getChargingSessionAsyncCallback]);


    return { charging, isLoading, getChargingSessionAsyncCallback }
}







export const useSessionStatisticsByPeriodAsync = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [sessionStatistics, setSessionStatistics] = useState<TotalEnergyAndCostByPeriodAndProvider[]>([])

    const getDashboardOverviewsByFilterAsyncCallback = useCallback(
        async () => {
            try {
                setIsLoading(true)

                const res = await getWeeklySessionStatisticsAsync()
                const statistics = res.data

                if (!statistics) {
                    throw Error('Statistics is undefined')
                }

                setSessionStatistics(statistics)

            } catch (error) {
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        },
        [],
    )


    useEffect(() => {
        getDashboardOverviewsByFilterAsyncCallback();
    }, [getDashboardOverviewsByFilterAsyncCallback]);


    return { sessionStatistics, isLoading, refetch: getDashboardOverviewsByFilterAsyncCallback }
}

